import React, { useState } from 'react';
import { FaReact, FaTelegram } from 'react-icons/fa';
import './App.css';
import logo from './logo.svg';

function App() {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="App-typing">Hello!. Welcome to  official Aysbiz website!</h1>
        <nav className={`App-nav ${navOpen ? 'open' : ''}`} onMouseEnter={() => setNavOpen(true)} onMouseLeave={() => setNavOpen(false)}>
          <ul className="App-link">
            <li>
              <a href="https://t.me/aysbiz" target="_blank" rel="noopener noreferrer">
                <FaTelegram className="App-icon" />
                Telegram Channel
              </a>
            </li>
            <li>
              <a href="https://t.me/aysbizsupbot" target="_blank" rel="noopener noreferrer">
                <FaTelegram className="App-icon" />
                Contact Support
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default App;

